import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { Header } from '@/header/Header.atom';
import { Footer } from '@/footer/Footer.molecule';
import { fetchConfiguration, fetchEveryoneDisabled } from '@/services/systemConfiguration.utilities';
import { setCurrentUser, setStateParams } from '@/workbench/workbench.actions';
import { fetchHeadlessCaptureMetadata, headlessRenderMode } from '@/services/headlessCapture.utilities';
import { fetchHeadlessCaptureMetadata as fetchHeadlessCaptureMetadataSqScreenshot } from '@/utilities/screenshot.utilities';
import { open as openSocket } from '@/utilities/socket.utilities';
import { sqLicenseManagementStore, sqStateSynchronizer, sqWorkbenchStore } from '@/core/core.stores';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { getWorkbench } from '@/workbench/workbench.utilities';
import { isWorksheet, setCurrentPath, wasWorksheet } from '@/main/routing.utilities';
import { LoadingBar } from '@/main/LoadingBar.molecule';
import { LicenseExpirationWarning } from '@/licenseManagement/LicenseExpirationWarning';
import { load as loadPlugins } from '@/plugin/plugin.actions';
import { SystemWarning } from '@/systemWarning/SystemWarning.organism';
import { checkLicenseStatus } from '@/licenseManagement/licenseManagement.actions';
import { TabTitle } from '@/core/TabTitle.molecule';
import { initializeRedactionService } from '@/utilities/redaction.utilities';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { onAllServerRequestsCanceled } from '@/services/notifier.service';
import { setupNotifierForWorkbook } from '@/workbook/workbook.actions';
import { setupNotifierForAnnotation } from '@/annotation/annotation.actions';
import { setupNotifierForHomescreen } from '@/homescreen/homescreen.actions';
import { warnToast } from '@/utilities/toast.utilities';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDarkreader } from 'react-darkreader';
import { getPendingRequestCount } from '@/requests/axios.utilities';
import { PUSH_WORKSTEP_IMMEDIATE } from '@/core/flux.service';
import { cancelAll } from '@/requests/pendingRequests.utilities';
import { cleanup } from '@/trendData/duration.actions';
import { cleanupOnUnload } from '@/main/cleanup.utilities';
import { switchLanguage } from '@/utilities/i18n.utilities';

/**
 * Wrapper component contains all the components that need to access workbench state.
 * This component takes care of subscribing to websocket channels, setting up state in the stores ...
 */
export const WorkbenchWrapperPage: React.FunctionComponent = () => {
  const [_isDark, { toggle }] = useDarkreader(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { pathname } = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const notDisplayingPresentationMode = !_.includes(pathname, 'present/');
  const displayLicenseWarning = useFluxPath(
    sqLicenseManagementStore,
    () => sqLicenseManagementStore.displayLicenseWarning,
  );

  useEffect(() => {
    // The order of operations and the actions taken are important for preventing worksteps leaking!
    setCurrentPath(pathname);
    // Dismiss toasts when transitioning from homescreen to worksheet or vice versa
    if (wasWorksheet() !== isWorksheet()) {
      toast.dismiss();
    }
    const urlSearchParams = new URLSearchParams(searchParams);
    const searchParamsAsObject = Object.fromEntries(urlSearchParams);
    const newStateParams = { ...params, ...searchParamsAsObject };
    // If we are already transitioning, it is unsafe to start another transition without canceling or
    // otherwise letting the in progress transition finish normally. It isn't safe because loading most
    // states requires asynchronous requests that change global state (the stores), if two transitions are
    // running at the same time then that leads to race conditions concerning the final global state. The
    // only way to effectively cancel the transition is to cause the page to reload. This isn't ideal for
    // the user experience, but it is better than letting state from two worksheets mingle (CRAB-14011, CRAB-35397).
    if (
      wasWorksheet() &&
      (getPendingRequestCount() > 0 || sqStateSynchronizer.isRehydrating || sqStateSynchronizer.isLoadingWorksheet()) &&
      sqWorkbenchStore.stateParams.worksheetId !== newStateParams.worksheetId
    ) {
      window.location.assign(_.join(_.compact([pathname, urlSearchParams.toString()]), '?'));
      return;
    }
    // Prevents reloading when the user double clicks
    if (!_.isEqual(sqWorkbenchStore.stateParams, newStateParams)) {
      if (wasWorksheet()) {
        // The getPendingRequestCount() should ensure that if any requests remain the page is reloaded, but just
        // in case something was not picked up we cancel all outstanding requests that were initiated on the worksheet
        cancelAll();
        // Ensures all websocket subscriptions are closed
        cleanupOnUnload();
        cleanup();
        // Flush any unsaved state into a workstep before navigating away
        sqStateSynchronizer
          .push(PUSH_WORKSTEP_IMMEDIATE, _.pick(sqWorkbenchStore.stateParams, ['workbookId', 'worksheetId']), true)
          .then(() => {
            setStateParams(newStateParams);
          });
      } else {
        setStateParams(newStateParams);
      }
    }
  }, [pathname, params, searchParams]);

  useEffect(() => {
    // set up notifiers
    setupNotifierForWorkbook();
    setupNotifierForAnnotation();
    setupNotifierForHomescreen();

    // Notify the user if an admin user cancels all server requests
    onAllServerRequestsCanceled(() => {
      warnToast({ messageKey: 'REQUEST_CANCELLATION.ALL_BY_ADMIN' });
    });

    initializeRedactionService();

    Promise.all([
      // Fetch initial data
      fetchConfiguration(),
      fetchEveryoneDisabled(),
      // setCurrentUser() must be before loading the plugins so admins only all in-development Add-ons
      setCurrentUser().then(() => loadPlugins().catch(_.noop)),
      checkLicenseStatus(pathname),
      // Miscellaneous setup
      fetchHeadlessCaptureMetadataSqScreenshot(),
      fetchHeadlessCaptureMetadata(),
      openSocket(sqWorkbenchStore.interactiveSessionId, getCsrfToken()),
    ])
      .then(() => {
        sqStateSynchronizer.rehydrate(getWorkbench(), {
          persistenceLevel: 'WORKBENCH',
          initializeMode: 'SOFT',
        });
      })
      .then(() => {
        // Set language and force loading of translation files
        // This has to happen AFTER the workbench state is available
        return switchLanguage(sqWorkbenchStore.userLanguage);
      })
      .then(() => {
        if (!_isDark && sqWorkbenchStore.darkMode && !headlessRenderMode()) {
          requestAnimationFrame(toggle);
        }
        setDataLoaded(true);
      });
  }, []);

  return (
    <>
      <TabTitle />

      {dataLoaded ? (
        <div className={classNames('flexRowContainer', 'fullViewport')} id="mainView" data-testid="mainView">
          {!headlessRenderMode() ? <LoadingBar /> : null}
          {notDisplayingPresentationMode && displayLicenseWarning ? <LicenseExpirationWarning /> : null}
          {notDisplayingPresentationMode ? <SystemWarning /> : null}
          {notDisplayingPresentationMode ? <Header darkModeToggleFn={toggle} /> : null}
          <div className="flexFill flexColumnContainer">
            <Outlet />
          </div>
          {notDisplayingPresentationMode ? <Footer /> : null}
        </div>
      ) : null}
    </>
  );
};
